import React from "react";
import { graphql } from "gatsby";
import { Formik, FormikErrors } from "formik";
import { compose } from "recompose";
import axios from "axios";
import isEmail from "email-validator";
import {
  Grid,
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import MainLayout from "../components/layout/MainLayout";
import { Container } from "../components/general";
import { SpecificTheme } from "../theme/default";
import { KontaktPageQuery } from "./__generated__/KontaktPageQuery";
import { PageProps } from "../typings";
import Footer from "../components/general/Footer";
import colors from "../theme/defaultTheme/colors";
import BackgroundContact from "../components/contact/BackgroundContact";
import SEO from "../components/SEO";

const styles = (theme: SpecificTheme) =>
  createStyles({
    root: {
      color: colors.whiteText,
      "& h2": {
        fontSize: "2.5rem",
      },
      "& a": {
        color: colors.whiteText,
        textDecoration: "none",
      },
    },
    contactTitle: {
      fontSize: "1.75rem",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0.9rem",
      },
      [theme.breakpoints.up("md")]: {
        height: "5vw",
      },
    },
    contactContainer: {
      backgroundColor: colors.mediumPetrol,
      width: "30vw",
      margin: "9vw 2vw 2vw 2vw",
      padding: "2vw",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
        marginTop: "2vw",
      },
    },
    formContainer: {
      margin: "2vw",
      padding: "2vw",
      width: "40vw",
      [theme.breakpoints.down("sm")]: {
        width: "90vw",
      },
    },
    textField: {
      background: "#fff",
      opacity: 0.9,
      width: "100%",
      margin: "0 4vw 4vh 0",
    },
    textInput: {
      margin: "0 0 0 0",
      paddingTop: "10px",
    },
    areaInput: {
      margin: "0",
    },
    submitButton: {
      background: "#1A728D",
    },
  });

type Props = WithStyles<typeof styles> & {} & PageProps<KontaktPageQuery>;

type FormProps = {
  name: string;
  email: string;
  message: string;
};

const Kontakt: React.FC<Props> = ({ classes, data, location }) => {
  const [isSent, setFormSent] = React.useState(false);

  return (
    <MainLayout location={location} title={"Kontakt"}>
      <Container
        variant="expandedUp"
        padding
        color="primary"
        className={classes.root}
      >
        <BackgroundContact>
          <Grid container justify="space-between" style={{ minHeight: "82vh" }}>
            <Grid item className={classes.formContainer}>
              {!isSent && (
                <>
                  <Typography variant="h1" className={classes.contactTitle}>
                    Kontaktieren Sie uns:
                  </Typography>
                  <Formik<FormProps>
                    initialValues={{
                      name: "",
                      email: "",
                      message: "",
                    }}
                    validate={(values) => {
                      const errors: FormikErrors<FormProps> = {};
                      if (!values.email) {
                        errors.email = "Wir benötigen eine E-Mail Adresse";
                      } else {
                        if (!isEmail.validate(values.email)) {
                          errors.email = "Keine gültige E-Mail Adresse";
                        }
                      }
                      if (!values.message) {
                        errors.message =
                          "Bitte schreiben Sie uns Ihre Nachricht";
                      }
                      return errors;
                    }}
                    onSubmit={(values, actions) => {
                      return axios
                        .post("https://formcarry.com/s/9x0NYi8Wuw3", values, {
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        })
                        .then((response) => {
                          response.status === 200
                            ? setFormSent(true)
                            : alert(
                                "Ein Fehler ist aufgetretten. Bitte senden Sie uns Ihre Anfrage direkt via EMail: info@twteam.ch"
                              );
                        })
                        .catch((reason) => {
                          alert(
                            "Ein Fehler ist aufgetretten. Bitte senden Sie uns Ihre Anfrage direkt via EMail: info@twteam.ch"
                          );
                          console.log(
                            "Catch error of form submission is: " +
                              JSON.stringify(reason)
                          );
                        });
                    }}
                    render={(props) => {
                      return (
                        <form
                          onSubmit={props.handleSubmit}
                          acceptCharset="UTF-8"
                        >
                          <TextField
                            variant="filled"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name}
                            name="name"
                            label="Ihr Name"
                            className={classes.textField}
                            error={props.touched.name && !!props.errors.name}
                            helperText={
                              props.touched.name ? props.errors.name : ""
                            }
                            InputProps={{
                              className: classes.textInput,
                            }}
                          />

                          <TextField
                            variant="filled"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.email}
                            name="email"
                            label="E-Mail"
                            className={classes.textField}
                            error={props.touched.email && !!props.errors.email}
                            helperText={
                              props.touched.email ? props.errors.email : ""
                            }
                            InputProps={{
                              className: classes.textInput,
                            }}
                          />

                          <TextField
                            variant="filled"
                            multiline
                            rows={6}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.message}
                            name="message"
                            label="Ihre Nachricht an uns"
                            className={classes.textField}
                            error={
                              props.touched.message && !!props.errors.message
                            }
                            helperText={
                              props.touched.message ? props.errors.message : ""
                            }
                            InputProps={{
                              className: classes.areaInput,
                            }}
                          />

                          <input type="hidden" name="_gotcha" />

                          <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            disabled={props.isSubmitting}
                          >
                            Senden
                          </Button>
                        </form>
                      );
                    }}
                  />
                </>
              )}
              {isSent && (
                <Grid item>
                  <Typography variant="h1" className={classes.contactTitle}>
                    Vielen Dank für Ihre Kontaktaufnahme
                  </Typography>
                  <p>Wir melden uns bei Ihnen</p>
                </Grid>
              )}
            </Grid>

            <Grid item className={classes.contactContainer}>
              <p>
                triple w team AG
                <br />
                Burghaldenstrasse 29
                <br />
                CH-5027 Herznach
              </p>
              <a href="mailto:info@twteam.ch">info@twteam.ch</a>
              <br />
              <a href="https://twteam.ch">www.twteam.ch</a>
              <br />
              <br />
              {/*<a href="tel:+41628782330">+41 62 878 23 30</a>
              <br />
              <br />*/}
              CHE-109.859.673
              <br />
              <a href="/AGB_twt.pdf" rel="noindex">
                Allgemeine Geschäfsbedingungen
              </a>
            </Grid>
          </Grid>
        </BackgroundContact>
      </Container>
      <Footer />
      {/* SEO, head params here */}
      <SEO
        general={{
          language: "de",
          contentType: "website",
          path: location.pathname,
          title: data.contentfulPages.seoTitle,
          description: data.contentfulPages.seoDescription,
        }}
      />
    </MainLayout>
  );
};

export default compose<Props, {}>(withStyles(styles))(Kontakt);

/*
 * Sizes is used for fluid containers
 * Resolution is used for fixed width
 */
export const kontaktQuery = graphql`
  query KontaktPageQuery {
    contentfulPages(page: { eq: "Kontakt" }) {
      seoTitle
      seoDescription
    }
    ...logoFragment
  }
`;
